import React, { Component } from 'react';

import './PlayerControl.css';


class PlayerControl extends Component {

    renderPlayerCells = () => {
        return (this.props.buttons.map(button => {

            let buttonClass = "PlayerButton ";
            if(!button.enabled) {
                buttonClass += "disabled";
            }

            return (
                <td >
                    <button
                    className={buttonClass}
                    key={button.color}
                    style={{
                        backgroundColor: button.color,
                    }}
                    onClick={button.enabled ? () => this.props.colorSelectOnClick(button.color) : null} >
                        </button>
                </td>
            )
        }));
    }

    render() {
        return (
            <div>
                <table className="PlayerControl">
                    <tbody>
                        <tr>
                            <td colSpan={5}>
                                <p className='text-left'>Choose your next color</p>
                            </td>
                            <td>
                                <p className="score-text-right">Score</p>
                            </td>
                        </tr>
                        <tr>
                            {this.renderPlayerCells()}
                            <td>
                                <h1 className="score-value-right">{this.props.score}</h1>
                            </td>
                        </tr>
                    </tbody>
                </table>

                
            </div>
        );
    }
}

export default PlayerControl;
