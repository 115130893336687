import React, {Component} from "react";
import Cell from "../Cell/Cell";

import './Board.css';


class Board extends Component {
  static defaultProps = {
    rowsCount: 10,
    columnsCount: 10,
    board: []
  }

  render() {
    const {rowsCount, columnsCount, board} = this.props;
    const size = 80 - rowsCount*3;

    // make table board
    let tblBoard = [];

    for (let row = 0; row < rowsCount; row++) {
      let wholeRow = [];
      for (let col = 0; col < columnsCount; col++) {
        wholeRow.push(
            <Cell
                key={board[row][col].id} 
                id={board[row][col].id} 
                color={board[row][col].color}
                player={board[row][col].player} 
                size={size}
            />);
      }

      tblBoard.push(<tr key={row}>{wholeRow}</tr>);
    }

    return (
      <table className="Board">
        <tbody>
          {tblBoard}
        </tbody>
      </table>
    )
  }
}


export default Board;
