import React, { Component } from "react";
import "./Cell.css";

class Cell extends Component {
    render() {
        const { player, color, size } = this.props;
        let classes = "Cell";

        const outerCellSize = `${size}px`;
        const innerCellSize = `${size - 2}px`;

        return (
            <td
                className={classes}
                style={{
                    width: outerCellSize,
                    height: outerCellSize,
                    backgroundColor: player === -1 ? color : "black",
                }}
            >
                <div
                    className="Cell-background-div"
                    style={{
                        backgroundColor: color,
                    }}
                >
                    <div
                        className="Cell-div-inner"
                        style={{
                            width: innerCellSize,
                            height: innerCellSize,
                            opacity: player === -1 ? "100%" : "0%",
                        }}
                    ></div>
                </div>
            </td>
        );
    }
}

export default Cell;
