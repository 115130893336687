import React, { Component } from "react";
import Game from "../Game/Game";
import { BoardSize, PlayerOpponent } from "../Game/GameLogic";
import GameSetup from "../GameSetup/GameSetup";

class NewGame extends Component {
    // static defaultProps = {
    //     boardSize: BoardSize.NORMAL,
    //     players: [PlayerOpponent.PLAYER, PlayerOpponent.AI_LEVEL_0, PlayerOpponent.OFF, PlayerOpponent.OFF]
    // }

    constructor(props) {
        super(props);

        this.state = {
            gameInProgress: false,
            rowsCount: 10,
            columnsCount: 20,
            opponent: PlayerOpponent.AI_LEVEL_0,
        };

        console.log("NewGame - constructor", this.state);
    }

    handleNewGame = () => {
        this.setState({
            ...this.state,
            gameInProgress: false,
        });
    };

    handleStartGame = (boardSize, player) => {
        
        this.setState({
            ...this.state,
            rowsCount: boardSize.rowCount,
            columnsCount: boardSize.colCount,
            gameInProgress: true,
            opponent: player,
        });
    };

    render() {
        const { gameInProgress, rowsCount, columnsCount, opponent } = this.state;
        console.log("NewGame - render", this.state);

        return (
            <div>
                {/* <SignInScreen /> */}
                {gameInProgress ? (
                    <Game
                        opponent={opponent}
                        rowsCount={rowsCount}
                        columnsCount={columnsCount}
                        newGame={this.handleNewGame}
                    />
                ) : (
                    <GameSetup startGame={this.handleStartGame} />
                )}
            </div>
        );
    }
}

export default NewGame;
