import React, {Component} from 'react'

import { availableBoardSizes, AvailableOpponents, BoardSize, PlayerOpponent } from '../Game/GameLogic';

import './GameSetup.css';
        
        
class GameSetup extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            boardSize: BoardSize.NORMAL,
            //players: [PlayerOpponent.PLAYER, PlayerOpponent.AI_LEVEL_0, PlayerOpponent.OFF, PlayerOpponent.OFF],
            players: PlayerOpponent.AI_LEVEL_0
        }

        console.log("GameSetup - constructor", this.state);
    }

    handleBoardSizeChange = (boardSize) => {
        this.setState({boardSize})
    }

    handlePlayerChange = (players) => {
        this.setState({players})
    }

    handleStartGame = () => {
        const {boardSize, players} = this.state;

        let newBoardSize = {
            boardSize,
            colCount: 20,
            rowCount: 10
        }

        if (boardSize === BoardSize.SMALL) {
            newBoardSize.colCount = 12;
            newBoardSize.rowCount = 7;
        } else if (boardSize === BoardSize.LARGE) {
            newBoardSize.colCount = 40;
            newBoardSize.rowCount = 20;
        }


        this.props.startGame(newBoardSize, players)
    }

    renderBoardSizeButtons = () => {
        const {boardSize} = this.state;

        return availableBoardSizes.map( board => {
            return(<button key={board.value} disabled={board.value === boardSize} onClick={() => this.handleBoardSizeChange(board.value)}>{board.text}</button>)
        })
    }

    renderOpponentButtons = () => {
        const {players} = this.state;

        return AvailableOpponents.map( player => {
            return(<button key={player.value} disabled={player.value === players} onClick={() => this.handlePlayerChange(player.value)}>{player.text}</button>)
        })
    }

    render() {    
        console.log("GameSetup - render", this.state);

        return (
            <div className="GameSetup">
                <h1>Start new game</h1>
                <h2>Select board size</h2>
                <div className="board-size-container">
                    {this.renderBoardSizeButtons()}
                </div>
                <h2>Select opponent</h2>
                <div className="opponent-container">
                    {this.renderOpponentButtons()}
                </div>
                <button id="start" className="button-start" onClick={() => this.handleStartGame()}>START</button>
            </div>
        )
    }
}


export default GameSetup;