import React, { Component } from "react";

import Board from "../Board/Board";
import { COLORS } from "../colorHelper";
import PlayerControl from "../PlayerControl/PlayerControl";
import {
    createNewGame,
    takeColorForPlayer,
    takeColorForOpponent,
    PlayerOpponent,
} from "./GameLogic";


class Game extends Component {
    static defaultProps = {
        rowsCount: 10,
        columnsCount: 10,
        numberOfPlayers: 2,
        opponent: PlayerOpponent.AI_LEVEL_0,
    };

    constructor(props) {
        super(props);

        this.state = createNewGame(
            props.rowsCount,
            props.columnsCount,
            props.numberOfPlayers,
            COLORS
        );

        // this.state = {
        //     board: initialBoard,
        //     players: [...playersStartingPosition],
        //     playersColors: [...playerStartingColors],
        //     playersScore: [...playersScore]
        // }

        console.log("constructor - initial state", this.state);
    }

    isPlayerWinner = (playerId) => {
        const { rowsCount, columnsCount, numberOfPlayers, newGame } =
            this.props;
        const { players } = this.state;

        if (
            players[playerId].length >
            (rowsCount * columnsCount) / numberOfPlayers
        ) {
            if (playerId === 0) {
                alert(`CONGRATULATIONS, YOU WON!`);
            } else {
                alert(`SORRY, YOU LOST!`);
            }
            newGame();
        }

        // LOCK GAME
    };

    colorSelectOnClick = (color, playerId) => {
        const { board, players, playersColors, playersScore } = this.state;
        const [newBoard, newPlayerCells] = takeColorForPlayer(
            board,
            players[playerId],
            color,
            playerId
        );

        let newPlayers = [...players];
        newPlayers[playerId] = newPlayerCells;

        let newPlayersColors = [...playersColors];
        newPlayersColors[playerId] = color;

        let newPlayersScore = [...playersScore];
        newPlayersScore[playerId] = newPlayerCells.length;

        //console.log("colorSelectOnClick - end", {newBoard, newPlayers, newPlayersColors});
        this.setState(
            {
                ...this.state,
                board: newBoard,
                players: [...newPlayers],
                playersColors: [...newPlayersColors],
                playersScore: [...newPlayersScore],
            },
            () => this.colorSelectForNextPlayer(newPlayersColors, playerId)
        );
    };

    colorSelectForNextPlayer = (updatedPlayersColors, prevPlayer) => {
        //console.log("colorSelectForNextPlayer - start", {prevPlayer, updatedPlayersColors});
        const { opponent } = this.props;
        const { board, players, playersScore } = this.state;
        //pick color that is not current and not oldColor

        if (this.isPlayerWinner(prevPlayer)) {
            return;
        }

        const playerId = prevPlayer + 1;
        const availableColors = COLORS.filter(
            (x) => updatedPlayersColors.indexOf(x) === -1
        );

        const [newBoard, newPlayerCells, chosenColor] = takeColorForOpponent(
            board,
            players,
            availableColors,
            playerId,
            opponent
        );

        let newPlayers = [...players];
        newPlayers[playerId] = newPlayerCells;

        let newPlayersColors = [...updatedPlayersColors];
        newPlayersColors[playerId] = chosenColor;

        let newPlayersScore = [...playersScore];
        newPlayersScore[playerId] = newPlayerCells.length;

        //console.log("colorSelectForNextPlayer - end", {newBoard, newPlayers, newPlayersColors});
        this.setState(
            {
                ...this.state,
                board: newBoard,
                players: [...newPlayers],
                playersColors: [...newPlayersColors],
                playersScore: [...newPlayersScore],
            },
            () => this.isPlayerWinner(playerId)
        );
    };

    render() {
        const { rowsCount, columnsCount } = this.props;
        const { board, playersScore, playersColors } = this.state;

        const playerColors = COLORS.map((x) => {
            return {
                color: x,
                enabled: playersColors.indexOf(x) === -1,
            };
        });

        return (
            <div>
                <PlayerControl
                    buttons={playerColors}
                    score={playersScore[0]}
                    colorSelectOnClick={(color) =>
                        this.colorSelectOnClick(color, 0)
                    }
                />
                <Board
                    rowsCount={rowsCount}
                    columnsCount={columnsCount}
                    board={board}
                />
            </div>
        );
    }
}

export default Game;
