export const BoardSize = {
    SMALL: "SMALL",
    NORMAL: "NORMAL",
    LARGE: "LARGE",
    GIANT: "GIANT",
};

export const PlayerOpponent = {
    PLAYER: "PLAYER",
    OFF: "OFF",
    HUMAN: "HUMAN",
    AI_LEVEL_0: "AI_LEVEL_0",
    AI_LEVEL_1: "AI_LEVEL_1",
    AI_LEVEL_2: "AI_LEVEL_2",
};

export const availableBoardSizes = [
    {
        value: BoardSize.SMALL,
        text: "Small (12x7)",
    },
    {
        value: BoardSize.NORMAL,
        text: "Normal (20x10)",
    },
    {
        value: BoardSize.LARGE,
        text: "Large (40x20)",
    },
    // {
    //     value: BoardSize.GIANT,
    //     text: "Giant (20x20)"
    // }
];

export const AvailableOpponents = [
    {
        value: PlayerOpponent.OFF,
        text: "Off",
    },
    // {
    //     value: PlayerOpponent.HUMAN,
    //     text: "Human"
    // },
    {
        value: PlayerOpponent.AI_LEVEL_0,
        text: "Easy",
    },
    {
        value: PlayerOpponent.AI_LEVEL_1,
        text: "Normal",
    },
    // {
    //     value: PlayerOpponent.AI_LEVEL_2,
    //     text: "Hard"
    // }
];

function createBoard(rowsCount, columnsCount, availableColors) {
    let board = [];

    for (let row = 0; row < rowsCount; row++) {
        let wholeRow = [];
        for (let col = 0; col < columnsCount; col++) {
            const randomColor =
                availableColors[
                    Math.floor(Math.random() * availableColors.length)
                ];

            const newCell = {
                id: `${row}-${col}`,
                color: randomColor,
                player: -1,
            };
            wholeRow.push(newCell);
        }

        board.push(wholeRow);
    }

    return board;
}

export function createNewGame(
    rowsCount,
    columnsCount,
    numberOfPlayers,
    availableColors
) {
    // Initialize players starting positions and colors
    let playersStartingPosition = [["0-0"]];
    let playerStartingColors = [availableColors[0]];
    let playersScore = [1];

    if (numberOfPlayers > 1) {
        playersStartingPosition.push([`${rowsCount - 1}-${columnsCount - 1}`]);
        playerStartingColors.push(availableColors[1]);
        playersScore.push(1);
    }
    if (numberOfPlayers > 2) {
        playersStartingPosition.push([`${0}-${columnsCount - 1}`]);
        playerStartingColors.push(availableColors[2]);
        playersScore.push(1);
    }
    if (numberOfPlayers > 3) {
        playersStartingPosition.push([`${rowsCount - 1}-${0}`]);
        playerStartingColors.push(availableColors[3]);
        playersScore.push(1);
    }

    let initialBoard = createBoard(rowsCount, columnsCount, availableColors);

    // Initialize players corners on board
    for (let index = 0; index < playersStartingPosition.length; index++) {
        let [newBoard, newPlayerCells] = takeColorForPlayer(
            initialBoard,
            playersStartingPosition[index],
            playerStartingColors[index],
            index
        );

        playersStartingPosition[index] = newPlayerCells;
        initialBoard = JSON.parse(JSON.stringify(newBoard));
        playersScore[index] = newPlayerCells.length;
    }

    console.log("createNewGame - board initialized", {
        initialBoard,
        playersStartingPosition,
        playerStartingColors,
        playersScore,
    });

    return {
        board: initialBoard,
        players: [...playersStartingPosition],
        playersColors: [...playerStartingColors],
        playersScore: [...playersScore],
    };
}

export function takeColorForPlayer(board, playerCells, color, playerId) {
    console.log("takeColorForPlayer - Player selected color", {
        color,
        playerId,
        playerCells,
    });

    let newBoard = JSON.parse(JSON.stringify(board));//[...board];
    let newPlayerCells = [...playerCells];

    function takeCellIfMatching(y, x) {
        if (y >= 0 && y < board.length && x >= 0 && x < board[0].length) {
            // console.log(
            //     "takeCellIfMatching - checking: ",
            //     {y,
            //     x,
            //     player: newBoard[y][x].player,
            //     cellColor: newBoard[y][x].color,
            //     color}
            // );
            if (
                newBoard[y][x].color === color &&
                newBoard[y][x].player === -1
            ) {
                newBoard[y][x].player = playerId;
                newPlayerCells.push(newBoard[y][x].id);
                console.log(
                    "takeColorForPlayer - Player taking cell: ",
                    newBoard[y][x].id
                );
            }
        }
    }

    let index = -1;
    while (index < newPlayerCells.length - 1) {
        index++;
        const cellId = newPlayerCells[index];
        const [y, x] = cellId.split("-").map(Number);
        // console.log("takeColorForPlayer - Checking neighbors of cell ", {
        //     index,
        //     cellId,
        //     y,
        //     x,
        //     color
        // });

        newBoard[y][x].player = playerId;
        newBoard[y][x].color = color;

        //check neighbors
        takeCellIfMatching(y - 1, x);
        takeCellIfMatching(y + 1, x);
        takeCellIfMatching(y, x - 1);
        takeCellIfMatching(y, x + 1);
    }

    //console.log("takeColorForPlayer - end", {newBoard, newPlayerCells});

    return [newBoard, newPlayerCells];
}

export function takeColorForOpponent(
    board,
    players,
    availableColors,
    playerId,
    level
) {
    //Level 0 - random
    let chosenColor =
        availableColors[Math.floor(Math.random() * availableColors.length)];

    // Level 1 - best move
    let bestPlayerMove = [];
    let bestPlayerScore = 0;

    if (level === PlayerOpponent.AI_LEVEL_1) {
        availableColors.map((color) => {
            const [newBoard, newPlayerCells] = takeColorForPlayer(
                board,
                players[playerId],
                color,
                playerId
            );

            console.log("takeColorForOpponent - checked one color", {
                    color,
                    newBoard,
                    newPlayerCells,
                });

            if (newPlayerCells.length > bestPlayerScore) {
                bestPlayerScore = newPlayerCells.length;
                chosenColor = color;
                bestPlayerMove = { 
                    newBoard: [...newBoard], 
                    newPlayerCells: [...newPlayerCells] };
                
            }
        });
    } else {
        const [newBoard, newPlayerCells] = takeColorForPlayer(
            board,
            players[playerId],
            chosenColor,
            playerId
        );
        bestPlayerMove = { 
            newBoard: [...newBoard], 
            newPlayerCells: [...newPlayerCells] };
    }

    console.log("takeColorForOpponent - color picked", {
        playerId,
        chosenColor,
        bestPlayerMove,
        availableColors,
        level
    });

    return [
        bestPlayerMove.newBoard,
        bestPlayerMove.newPlayerCells,
        chosenColor,
    ];
}
