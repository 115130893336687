import GameSetup from './GameSetup/GameSetup';

import './App.css';
import NewGame from './NewGame/NewGame';


function App() {
  return (
    <div className="App">
      <NewGame />
    </div>
  );
}

export default App;
